<template>
  <div
    tabindex="0"
    @keydown="preventSelectText"
    @keyup="allowSelectText">
    <v-app
      class="main-app"
      :class="[$route.params.fieldName, $route.name, offSelectedText]">
      <AppLoadingRoute v-if="isLoadingRoute" />
      <PromiseDialogsWrapper />
      <CreateProjectModal />
      <ProjectDetailInfoModal />
      <ShareModal />
      <SuggestDialogSnackbar />

      <!-- This modal popups for new users -->
      <!-- to show tour video -->
      <TourModal v-if="viewTutorial && !$vuetify.breakpoint.smAndDown" />

      <!-- Sizes your content based upon application components -->
      <v-main :class="[$route.name, computedMargins]">
        <!-- Left Navigation Bar -->
        <NavBar v-if="isNotLoginRelatedPage && userInfo && isNotProject" />

        <!-- Top Navigation Bar -->
        <AppHeader
          v-if="isNotLoginRelatedPage && isNotProject"
          :user-info="userInfo" />

        <AppBannerManagePayment v-if="!useRemoveUpgradeNotice" />
        <transition
          name="page"
          mode="out-in">
          <router-view />
        </transition>
      </v-main>

      <Spinner />
      <SnackBar />
      <ProjectDetailsExportSnackBar />
      <MaintenanceBanner
        v-if="maintenanceMode && showMaintenanceBanner"
        @close="showMaintenanceBanner = false" />
      <AppBanner
        v-if="showMainBanner && paymentStatus"
        :massage="paymentStatus"
        @close="showMainBanner = false" />
      <AppBanner
        v-if="banner.value"
        :massage="banner.message"
        @close="closeBanner" />

      <!-- preview files / images in carousel -->
      <FilePreview />

      <!-- Right bottom AI Chat -->
      <AppAIChat v-if="isNotLoginRelatedPage && notQuickLinkPage" />
    </v-app>
  </div>
</template>
<script>
import {
  mapActions, mapState, mapGetters, mapMutations,
} from 'vuex';
import { PromiseDialogsWrapper } from 'vue-promise-dialogs';
import { WORKSPACE_PERMISSION } from '@/constants/userPermissions';
// components
import AppAIChat from '@/components/App/AppAIChat';
import AppBanner from '@/components/App/AppBanner';
import AppLoadingRoute from '@/components/App/AppLoadingRoute';
import CreateProjectModal from '@/components/Projects/ProjectsCreateProjectModal';
import FilePreview from '@/components/App/AppFilePreview';
import MaintenanceBanner from '@/components/App/AppMaintenanceBanner';
import NavBar from '@/components/App/AppNavBar';
import ProjectDetailInfoModal from '@/components/App/AppProjectDetailInfoModal';
import ProjectDetailsExportSnackBar
  from '@/components/ProjectDetails/ProjectDetailsExportTo/ProjectDetailsExportSnackBar';
import ShareModal from '@/components/App/AppShareModal';
import SnackBar from '@/components/App/AppSnackBar';
import Spinner from '@/components/App/AppLoadingSpinner';
import TourModal from '@/components/App/TourModal';
// mixins
import OfflineDetection from '@/mixins/OfflineDetection';
import ConfirmStayInDirtyForm from '@/mixins/confirmLeaveWithUnsavedData';
export default {
  name: 'App',
  components: {
    AppAIChat,
    AppBanner,
    AppLoadingRoute,
    CreateProjectModal,
    FilePreview,
    MaintenanceBanner,
    NavBar,
    ProjectDetailInfoModal,
    ProjectDetailsExportSnackBar,
    PromiseDialogsWrapper,
    ShareModal,
    SnackBar,
    Spinner,
    TourModal,
    AppBannerManagePayment: () => import('@/components/App/AppBanner/AppBannerManagePayment'),
    AppHeader: () => import('@/components/App/AppHeader'),
    SuggestDialogSnackbar: () => import('@/components/App/AppSuggestDialog/SuggestDialogSnackbar'),
  },
  mixins: [
    OfflineDetection,
    ConfirmStayInDirtyForm,
  ],
  data() {
    return {
      drawer: false,
      showMaintenanceBanner: true,
      showMainBanner: true,
      fileRemoveCounter: 0,
      isSelectTextOnPage: true,
    };
  },
  computed: {
    ...mapState(['userInfo', 'loginRelatedPages', 'banner', 'isLoadingRoute', 'viewTutorial']),
    ...mapState('ManageFiles', ['cachedFiles']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', [
      'maintenanceMode',
      'useRemoveUpgradeNotice',
      'useMakePageFollowable',
    ]),
    ...mapGetters('UserProfile', ['isUnsuccessfulPayment']),
    ...mapGetters('Workspace', ['getActiveWorkspace']),
    computedMargins() {
      if (this.isNotProject && this.isNotLoginRelatedPage && this.$route.name !== 'community-collections') {
        return this.$vuetify.breakpoint.smAndDown ? 'mb-32' : 'mb-25';
      }
      return '';
    },
    isNotLoginRelatedPage() {
      const name = this.$route?.name;
      return name && !this.loginRelatedPages.includes(name);
    },
    isNotProject() {
      return !(this.$vuetify.breakpoint.lgAndUp && ['schedule-library', 'project-library'].includes(this.$route.name));
    },
    notQuickLinkPage() {
      return !(['project-library', 'shared-collection'].includes(this.$route.name));
    },
    offSelectedText() {
      return !this.isSelectTextOnPage ? 'select-page-none' : '';
    },
    paymentStatus() {
      const status = this.getActiveWorkspace?.status === 'paidFailed';
      const type = this.getActiveWorkspace?.type === 'premiumExpired';
      if (status || type) {
        return 'You are in the Read-Only mode as the last payment was unsuccessful. Please check your payment method to be able to use all the premium features again.';
      } else if (this.isUnsuccessfulPayment) {
        const group = this.premiumGroup ? 'Premium' : 'Collaborator';
        return `The last payment was unsuccessful. Please check your payment method to be able to use all the ${group} features again.`;
      }
      return false;
    },
  },
  watch: {
    activeWorkspaceId(val) {
      if (!val) {
        this.setStaringFilteredViews([]);
        return;
      }
      this.subscribeWorkspacePermissionChange();
      this.getStarredProjectList();
      if (this.useMakePageFollowable) {
        this.subscribeFollowedWorkspacePage();
      }
    },
    cachedFiles(val) {
      if (!val.length || this.fileRemoveCounter > 0) {
        return;
      }
      this.fileRemoveCounter++;
      this.removeFileIfTimeIsNotLeft();
    },
  },
  async created() {
    this.setsStoreIsMobile();
    this.subscribeToScheduleExport();
  },
  beforeDestroy() {
    this.removeSubscriptions([WORKSPACE_PERMISSION]);
  },
  methods: {
    ...mapActions({
      subscribeToListOfExportScheduleFiles: 'ExportSchedule/subscribeToListOfExportScheduleFiles',
      subscribeToListOfFilesToNotify: 'ExportSchedule/subscribeToListOfFilesToNotify',
      subscribeWorkspacePermissionChange: 'UserPermissions/subscribeWorkspacePermissionChange',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
      removeFileIfTimeIsNotLeft: 'ManageFiles/removeFileIfTimeIsNotLeft',
      subscribeFollowedWorkspacePage: 'MyPages/subscribeFollowedWorkspacePage',
      getStarredProjectList: 'Projects/getStarredProjectList',
    }),
    ...mapMutations({
      closeBanner: 'closeBanner',
      setIsMobile: 'setIsMobile',
      setStaringFilteredViews: 'Projects/setStaringFilteredViews',
    }),
    subscribeToScheduleExport() {
      this.subscribeToListOfExportScheduleFiles();
      this.subscribeToListOfFilesToNotify();
    },
    setsStoreIsMobile() {
      const isMobile = Boolean(this.$mobileDetect.mobile()) || Boolean(this.$mobileDetect.tablet());
      this.setIsMobile(isMobile);
    },
    preventSelectText(e) {
      if (e.shiftKey && !e.repeat) this.isSelectTextOnPage = false;
    },
    allowSelectText(e) {
      if (e.key === 'Shift') this.isSelectTextOnPage = true;
    },
  },
};
</script>
<style lang="scss" src="./App.scss"></style>
